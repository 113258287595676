﻿import { $, $$, get, getDataText, postDataText, updateLanguageUrls } from "@/Utils";
import { fillPanelAndInitForm } from "@/sharedComponents/Header/CustomerService";
import {initTabs} from "@/sharedComponents/Tabs";
import { initCarousels } from "@/sharedComponents/Carousel/Carousel";

function initMenu() {
    const activeLink = $(".content-sidebar__link--active");
    if (activeLink) {
        const url = activeLink.getAttribute("data-url");
        const contentId = activeLink.getAttribute("data-content-id");
        history.replaceState({ contentId: contentId }, null, url);
    }
    $$("[data-content-id]").forEach(x => x.addEventListener("click", () => {
        const id = parseInt(x.getAttribute("data-content-id"));
        const url = x.getAttribute("data-url");
        history.pushState({ contentId: id }, null, url);
        selectContent(id, x);
    }));
}

function selectContent(id: number, link: HTMLElement) {

    const activeClass = "content-sidebar__link--active";
    if (link.classList.contains(activeClass)) {
        return;
    }
    $$(".content-sidebar__link").forEach(x => {
        x.classList.remove(activeClass)
        x.closest(".list-item")?.classList.remove("link-title--active")
    });
    link.classList.add(activeClass);
    link.closest(".list-item")?.classList.add("link-title--active");
    centerSelectedLink();

    updateLanguageUrls(link);
    const callback = function (resp: string) {
        get("staticContentContainer").innerHTML = resp;
        initButtons();
    }    
    document.title = link.querySelector(".link-title")?.innerHTML;
    postDataText(window.config.staticContentUrl, id, callback);
}

function initButtons() {
    get("return-product")?.addDataBoundEventListener("click", () => {
        getDataText(window.config.productReturnFormUrl, fillPanelAndInitForm);
    });
    get("best-price-warranty")?.addDataBoundEventListener("click", () => {

        getDataText(window.config.bestPriceWarrantyFormUrl, fillPanelAndInitForm);
    });

    $$('.read-more-content').forEach(x => {
        x.addDataBoundEventListener("click", () => {
            x.closest(".section").classList.toggle("section--read-more");
        })
    })

    const searchMTButton = get("searchMTButton");
    if (searchMTButton) {
        const num = get("MTnumber") as HTMLInputElement;
        const code = get("MTcode") as HTMLInputElement;
        searchMTButton.addDataBoundEventListener("click", () => {
            const fr = get("MTframe") as HTMLIFrameElement;
            const lang = document.documentElement.lang.toUpperCase();
            const countryCode = (get("country-code") as HTMLInputElement).value;
            fr.src = "https://net.tovacom.com/mserv/trackandtrace?taskId=" + num.value + "&identificationId=" + code.value.replace('+', '%2B') + "&lang=" + lang + "&consumer=euronics-" + countryCode;
            fr.style.display = "block";
        });
    }
}

function responsiveStaticContentLayout() {

    if (window.isResponsive) {
        initHorizontalScroll();
    }
}

const scrollObserver = new IntersectionObserver(entries => {

    const scroller = $(".scroll-category")
    entries.forEach(e => {
        if (e.isIntersecting) {
            scroller.classList.remove("d-flex")
        }
        else {
            scroller.classList.add("d-flex")
        }
    })
}, {
    root: $(".content-sidebar"),
    threshold: [1]
})

function initHorizontalScroll() {
    const sortList = $(".content-sidebar__list")
    if (!sortList || sortList.hasAttribute("data-bound")) return
    sortList.setAttribute("data-bound", "")
    scrollObserver.observe(sortList.lastElementChild as Element)
}

function bindScrollers() {
    centerSelectedLink();

    window.addEventListener("resize", () => {
        centerSelectedLink();
    });

    $(".scroll-left")?.addEventListener("click", () => {
        $(".sidebar-menu")?.scrollTo({
            left: $(".sidebar-menu").scrollLeft - 200,
            behavior: 'smooth'
        });
    });

    $(".scroll-right")?.addEventListener("click", () => {
        $(".sidebar-menu")?.scrollTo({
            left: $(".sidebar-menu").scrollLeft + 200,
            behavior: 'smooth'
        });
    });
}

function centerSelectedLink() {
    if (!window.isResponsive) return;

    const selectedCategory = $(".sidebar-menu .link-title--active");

    $(".sidebar-menu")?.scrollTo({
        left: selectedCategory?.offsetLeft - (window.innerWidth - selectedCategory?.offsetWidth) * 0.5,
        behavior: 'smooth'
    });
}

function initOpenLoyaltyProgramConditions(){
    const conditionsId = "loyalty-program-conditions";
    const conditionsElem = $("#"+conditionsId);
    let urlHasLoyaltyProgramVariable = window.location.href.includes("?"+conditionsId);
    if(conditionsElem && urlHasLoyaltyProgramVariable){
        conditionsElem.classList.add("accordion__item--active");
        window.history.replaceState(null, '', window.location.href.replace("?" + conditionsId, ""));
        sessionStorage.setItem('scrollPosition', window.scrollY+(conditionsElem.offsetTop - 100).toString());
    }
}

window.addEventListener("load", () => {
    initTabs();
    initMenu();
    initButtons();
    bindScrollers();
    initCarousels();
    window.responsiveObserverFunctions = (window.responsiveObserverFunctions || [])
    window.responsiveObserverFunctions.push(responsiveStaticContentLayout)
    initOpenLoyaltyProgramConditions();
});
window.addEventListener("popstate",
    e => {
        if (e.state && e.state.contentId) {
            const id = parseInt(e.state.contentId);
            const link = $(`[data-content-id="${id}"]`);
            selectContent(id, link);
        }
    });

window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('scrollPosition', window.scrollY+'');
});

window.addEventListener('load', () => {
    const savedPosition = sessionStorage.getItem('scrollPosition');

    if (savedPosition !== null)
        window.scrollTo(0, Number(savedPosition));
});